import React, { useContext } from 'react'
import { GlobalDataContext } from '../context/context'
import BaseLayout from '../components/global/BaseLayout'
import TransparentHeader from '../components/global/TransparentHeader'

const ApplieToJob = () => {
  const { rpdata } = useContext(GlobalDataContext)
  return (
    <BaseLayout PageName="Applies To Job">
      <TransparentHeader
        headertitle="Applies To Job"
        Subheader="Applies To Job"
        bgimg={`${rpdata?.gallery?.[10]}`}
      />

      <div className='w-full py-28 bg-center bg-cover'
        style={{
          backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/Banner%2Ffondo-form.png?alt=media&token=c3ed9c13-9d02-440e-9e57-405bd1efd7c8")'
        }}
      >
        <div className='w-4/5 mx-auto '>
          <h2 className='text-center pb-10 text-white'>Fill In The Requested Fields</h2>
          <div className='w-full bg-white p-10 rounded-lg shadow-lg'>
            <div className="elfsight-app-14c53fb9-66c5-47c3-b20d-34e8b91b25d0"></div>
          </div>
        </div>

      </div>
    </BaseLayout>
  )
}

export default ApplieToJob